const input = require('./data_summary.json');
const d3Array = require("d3-array");

export const summarize_data = () => {
  const rolledByAssay = d3Array.rollup(input, v => v.length, d => d.Assay);
  const rolledByTissue = rollupByKey(input, 'Tissue');
  const rolledByExposure = rollupByKey(input,'Exposure');
  const resAssay = Array.from(rolledByAssay);
  const resTissue = Array.from(rolledByTissue);
  const resExposure = Array.from(rolledByExposure);

  return { assay: reformat(resAssay), tissue: reformat(resTissue), exposure: reformat(resExposure) };
}

function reformat(list) {
  const labels = list.map(d => {
    const [key, ] = d;
    return key;
  })

  const series = list.map(d => {
    const [, value] = d;
    return value;
  })

  return { labels, series }
}

function rollupByKey(input, key) {
  return d3Array.rollup(input, v => v.length, d => d[key])
}