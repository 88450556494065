// /* global tw */
import React from 'react';
// {useState, useEffect} import { Grid, Card, CardContent } from
// '@material-ui/core';
import {summarize_data} from '../scripts/data';

// import Chart from 'react-apexcharts'; import { css } from 'emotion' import
// BubbleWidget from '../components/BubbleWidget';
import '../tailwind.css';
import '../stitches.css';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import loadable from 'loadable-components';
const Chart = loadable(() => import ('react-apexcharts'));
// const DATA_SUMMARY_URL = 'https://target.wustl.edu/data_summary.json';

export default() => (
		<div className='flex flex-col justify-between h-screen'>
				<div className='border-b-2 border-grey-light'>
						<Nav/>
				</div>
				<div className="font-sans">
						<section
								className="antialiased w-full bg-grey-lighter text-left text-black py-8 text-gray-600 font-sans">
								<div
										className="container mx-auto flex px-5 py-8 md:flex-row flex-col items-center">
										<div
												className="lg:flex-grow md:w-3/4 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
												<h1 className="title-font sm:text-4xl text-3xl mb-8 font-medium text-gray-900">Consortium Investigator&nbsp;
														<br className="hidden lg:inline-block"/>elected to&nbsp;
														<br className="hidden lg:inline-block"/>National Academy of Sciences
												</h1>
												<p className="my-8 leading-relaxed">Congratulations Dr. Bartolomei!!</p>
												<div className="flex justify-center">
														<a
																className='pt-8 no-underline'
																href="http://www.nasonline.org/news-and-multimedia/news/2021-nas-election.html">
																<button
																		className="bg-black hover:bg-grey-darkest text-white hover:text-white py-3 px-8 my-8 uppercase text-xs tracking-wide">See announcement</button>
														</a>

												</div>
										</div>
										<div className="responsive">
												<img
														className="rounded"
														alt="hero"
														src="https://hosting.med.upenn.edu/epigenetics/wp-content/blogs.dir/72/files/sites/72/2017/05/portrait-bartolomei.jpg"/>
										</div>
								</div>
						</section>
						<div className="container m-auto flex flex-col md:flex-row max-w-xl">
								<div className="flex flex-col w-full lg:w-1/2 justify-center items-start py-8">
										<label className="uppercase">TaRGET - II consortium</label>
										<h1 className="my-4 font-normal">Our purpose</h1>
										<p className="leading-normal mb-4">The
												<strong>TaRGET(Toxicant Exposures and Responses by Genomic and Epigenomic
														Regulators of Transcription)
												</strong>
												Program is a research consortium funded by the National Institute of
												Environmental Health Sciences (NIEHS). The goal of the collaboration is to
												address the role of environmental exposures in disease pathogenesis as a
												function of epigenome perturbation, including understanding the environmental
												control of epigenetic mechanisms and assessing the utility of surrogate tissue
												analysis in mouse models of disease-relevant environmental exposures.</p>
										<a
												href="https://www.niehs.nih.gov/research/supported/health/envepi/target/index.cfm">
												<button
														className="bg-transparent hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent">Learn More</button>
										</a>

								</div>
								<div className="w-full lg:w-1/2 lg:py-6"><img src="/white-paper.png" alt='whitepaper_figure' className="w-full"/>
										<div className='flex justify-center text-grey-dark text-sm mt-4 ml-4'>
												Source:
												<div className='italic pl-2'>
														Nature Biotechnology
												</div>
												<div className='font-mono px-2'>
														ISSN 1546-1696
												</div>
												(<a className='no-underline' href="https://www.nature.com/articles/nbt.4099">online</a>)

										</div>
								</div>
						</div>
				</div>

				<div
						className="antialiased font-sans w-full bg-grey-lighter text-left text-black py-8">
						<div className="container mx-auto max-w-xl py-8 font-normal leading-normal">
								<div className="font-sans text-center p-8">
										<LivePreviewExample/>
										<div className="capitalize font-medium m-8"></div>
										<a href="https://data.targetepigenomics.org">
												<button
														className="bg-black hover:bg-grey-darkest text-white hover:text-white py-3 px-6 uppercase text-xs tracking-wide">Go to Data Portal</button>
										</a>
								</div>
								{/* <iframe title="chart" src="/sunburst" width="600" height="400"></iframe>  */}

						</div>

				</div>
				<Footer/>
		</div>

)

export function LivePreviewExample() {

		// const [dataSummary, 		setDataSummary] = useState({}); const [loaded,
		// 		setLoaded] = useState(false); useEffect(() => { 		fetchData(); }, []) const
		// fetchData = async() => { 		const res = await fetch(DATA_SUMMARY_URL);
		// 		const data = await res.json(); 		const summarizedData =
		// summarize_data(data); 		console.log(summarizedData);
		// 		setDataSummary(summarizedData); 		setLoaded(true) }
		const dataSummary = summarize_data();

		// if (!loaded) { 		return <p>Loading...</p> } else {
		return (
				<div className="flex justify-center">
						<div>
								<div className="mb-4">
										<div className="p-3">
												<h5 className="card-title font-weight-bold font-size-lg">
														Assays
												</h5>
												{/* <p className="card-text">
																Subtitle to describe something more
														</p> */}
												<div className="d-flex justify-content-center">
														<Chart
																options={{
																labels: dataSummary.assay.labels,
																chart: {
																		events: {
																				dataPointSelection: (event, chartContext, config) => {
																						const stringClicked = config.w.config.labels[config.dataPointIndex];
																						window
																								.location
																								.assign(`https://data.targetepigenomics.org/?Assay=%5B"${stringClicked}"%5D`);
																				}
																		}
																}
														}}
																series={dataSummary.assay.series}
																type="donut"
																width="380"/>
												</div>
										</div>
								</div>
						</div>
						<div item xs={12} sm={6} md={4}>
								<div className="mb-4 ml-8">
										<div className="p-3">
												<h5 className="card-title font-weight-bold font-size-lg">
														Exposure
												</h5>
												{/* <p className="card-text">
																One liner description
														</p> */}
												<div className="d-flex justify-content-center">
														<Chart
																options={{
																labels: dataSummary.exposure.labels,
																chart: {
																		events: {
																				dataPointSelection: (event, chartContext, config) => {
																						const stringClicked = config.w.config.labels[config.dataPointIndex];
																						window
																								.location
																								.assign(`https://data.targetepigenomics.org/?Exposure=%5B"${stringClicked}"%5D`);
																				}
																		}
																}
														}}
																series={dataSummary.exposure.series}
																type="donut"
																width="380"/>
												</div>
										</div>
								</div>
						</div>
				</div>
		);
}
